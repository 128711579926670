import Settings from './Settings'
import GettingStarted from './GettingStarted'
import Home from './Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="settings" element={<Settings />} />
        <Route path="getting-started" element={<GettingStarted />} />
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
