import { Notification, Modal, CircularProgressBar, ToggleSwitch, FormField, SectionHelper, InputArea, Input, CustomModalLayout, TableActionCell, AnnouncementModalLayout, Badge, Card, PageSection, MarketingLayout, Table, Loader, Text, TableToolbar, Image, EmptyState, Page, Button, TextButton, Layout, Cell, Box, Tooltip, IconButton, WixDesignSystemProvider } from '@wix/design-system'
import '@wix/design-system/styles.global.css';
import { PremiumFilled, HelpCircle, Flag, ExternalLinkSmall, Invoice } from '@wix/wix-ui-icons-common'
import React from 'react';
import { To, useNavigate, useLocation } from "react-router-dom";
import { requestMap, responseMap } from './utils/map.js'
import axios from 'axios';
import { ReactTagManager } from 'react-gtm-ts';

const tagManagerArgs = {
    code: 'GTM-WZQPMC7'
}

const appId = ""

function Home() {
    const baseURL = "https://certifiedcode.editorx.io/ecpay-invoicing/_functions";
    const [showTrialEndedSection, setShowTrialEndedSection] = React.useState(false);
    const { search } = useLocation();

    function convertObjToList(obj: { obj: Object }, map: { map: Object }) {
        return Object.keys(obj).map((key) => { return `${(map as any)[key] || key}: ${(obj as any)[key] || "(無)"}` }).join("\n")
    }

    ReactTagManager.init(tagManagerArgs)
    let navigate = useNavigate();
    const routeChange = (path: To) => {
        navigate(path);
    }

    const [transactions, setTransctions] = React.useState([
        {
            wixTxnId: "-",
            ecpayInvoiceNumber: "-",
            ecpayInvoiceDate: "-",
            status: <Badge skin='neutral'>-</Badge>
        }
    ]);
    // const currentTransationData = transactions[0]
    const [dashboard, setDashboard] = React.useState(null);
    const [merchantId, setMerchantId] = React.useState("");
    const [hashKey, setHashKey] = React.useState("");
    const [hashIV, setHashIV] = React.useState("");
    const [isCreateOnTransaction, setIsCreateOnTransaction] = React.useState(false);
    const [showErrorBanner, setShowErrorBanner] = React.useState(false);
    const [errorText, setErrorText] = React.useState("未知的錯誤");
    const [successText, setSuccessText] = React.useState("成功");
    const [showSuccessBanner, setShowSuccessBanner] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [shownUpgradeModal, setShownUpgradeModal] = React.useState(false);
    const [shownTransactionModal, setShownTransactionModal] = React.useState(false);
    const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
    const [currentTransaction, setCurrentTransaction] = React.useState("");

    React.useEffect(() => {
        loadDashboard()
    }, []);

    function loadDashboard() {
        axios.get(`${baseURL}/dashboard`, {
            params: {
                instance: new URLSearchParams(window.location.search).get('instance')
            }
        }).then((response) => {
            setDashboard(response.data);
            setHashKey(response.data?.configuration?.hashKey);
            setHashIV(response.data?.configuration?.hashIV);
            setMerchantId(response.data?.configuration?.merchantId);
            if (!response.data?.configuration?.hashKey || !response.data?.configuration?.hashIV || !response.data?.configuration?.merchantId) {
                if (window.location.search.includes('fromSetup')) {
                    setShownSettingsModal(true);
                } else {
                    routeChange(`/getting-started?instance=${new URLSearchParams(window.location.search).get('instance')}`)
                }
            }
            setIsCreateOnTransaction(response.data?.configuration?.isCreateOnTransaction);
            setTransctions((response.data as any)['transactions'].map((v: { transactionId: string; }) => {
                const invoice = (response.data as any)['invoices'].find((inv: { _id: string; }) => inv._id === v.transactionId)
                return {
                    wixTxnId: v.transactionId || "",
                    ecpayInvoiceNumber: invoice?.ecpayResponse?.InvoiceNumber || "",
                    ecpayInvoiceDate: invoice?.ecpayResponse?.InvoiceDate || "",
                    status: <Tooltip placement="left" appendTo="window" content={invoice?.ecpayResponse?.RtnCode === "1" ? '要管理已開立的發票，請前往綠界管理介面進行。' : (invoice?.ecpayError ? invoice?.ecpayError : '要開立發票，請到右側動作欄點擊「開立發票」')} size="small">
                        <Badge size="small" skin={invoice?.ecpayResponse?.RtnCode === "1" ? 'success' : (invoice?.ecpayError ? "danger" : 'neutral')}>{invoice?.ecpayResponse?.RtnMsg || (invoice?.ecpayError ? "發生錯誤" : '待開立')}</Badge>
                    </Tooltip>
                }
            }))
            setIsLoaded(true);
        }).catch((error) => {
            setIsLoaded(true);
            throw error
        })
    }

    function createInvoice(transactionId: string) {
        axios
            .get(`${baseURL}/createinvoice/${transactionId}`, {
                params: {
                    instance: new URLSearchParams(window.location.search).get('instance')
                }
            })
            .then((response: any) => {
                loadDashboard();
                console.log(response);
                if (!response['data']) {
                    setSuccessText("成功建立發票")
                    setShowErrorBanner(true);
                }
                if (response['data']['success'] === true) {
                    setShowSuccessBanner(true);
                } else {
                    if (response['data']['reason']) {
                        setErrorText(response['data']['reason'])
                    }
                    setShowErrorBanner(true);
                }
            });
    }

    function getUpgradeUrl(isLifetime: boolean) {
        axios
            .get(`${baseURL}/upgradeUrl`, {
                params: {
                    instance: new URLSearchParams(window.location.search).get('instance'),
                    isLifetime
                }
            })
            .then((response: any) => {
                window.open(response['data'], '_blank')
            });
    }

    function updateSettings() {
        axios({
            method: 'post',
            url: `${baseURL}/updatesettings?instance=${new URLSearchParams(window.location.search).get('instance')}`,
            data: JSON.stringify({
                merchantId,
                hashKey,
                hashIV,
                isCreateOnTransaction
            }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response: any) => {
                setShownSettingsModal(false);
                console.log(response);
                if (!response['data']) {
                    setShowErrorBanner(true);
                }
                if (response['data']['success'] === true) {
                    setSuccessText("成功更新設定")
                    setShowSuccessBanner(true);
                } else {
                    setSuccessText("無法更新設定")
                    setShowErrorBanner(true);
                }
            });
    }

    if ((!dashboard || dashboard['success'] === false) && isLoaded) {
        return (
            <WixDesignSystemProvider features={{ newColorsBranding: true }}>
                <Page sidePadding={0}>
                    <Page.Content>
                        <Box padding="10px" align="center" verticalAlign="middle" height={"90vh"} direction="vertical">
                            <Cell>
                                <CircularProgressBar error value={100} />
                            </Cell>
                            <EmptyState
                                theme={'page-no-border'}
                                title="哎呀！？出事了 🤯"
                                subtitle="請刷新並重試。如果仍然無法正常工作，請通過右下角的 Messenger 聯繫支持人員。"
                            />
                        </Box>
                    </Page.Content>
                </Page>
            </WixDesignSystemProvider>)
    }

    if (!isLoaded) return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}><Page height='100vh'>
            <Page.Content>
                <Box height={"90vh"} direction="vertical" verticalAlign="middle">
                    <Loader text="加載中" />
                </Box>
            </Page.Content>
        </Page></WixDesignSystemProvider>
    );

    if (!dashboard) {
        return null
    }

    if (!dashboard['configuration']) {
        setShownSettingsModal(true)
    }

    const siteId = dashboard['instance']['site']['siteId']
    const isUpgraded = !dashboard['instance']['instance']['isFree']
    const isUnlocked = true
    if (!isUnlocked) {
        setShowTrialEndedSection(true)
    }
    const totalProductCount = transactions.length;
    const primaryAction = (wixTxnId: string, isCompleted: boolean) => {
        return ({
            text: '查看',
            onClick: () => {
                setCurrentTransaction(wixTxnId);
                isUnlocked ? setShownTransactionModal(true)
                    : setShownUpgradeModal(true)
            },
            disabled: !isCompleted
        });
    }

    const secondaryActions = (wixTxnId: string) => {
        return ([
            {
                text: '回報問題',
                icon: <Flag />,
                onClick: () => { window.Intercom('showNewMessage', `回報問題：Wix交易號：${wixTxnId}`) },
            },
            {
                text: '開立發票',
                icon: <Invoice />,
                onClick: () => { createInvoice(wixTxnId) },
                disabled: wixTxnId ? !!(dashboard as any)['invoices'].find((v: { _id: string; }) => v._id === wixTxnId)?.ecpayRequest : false
            }

        ])
    }
    const columns = [
        {
            title: 'Wix交易號', render: (row: { wixTxnId: string; }) => (<Box gap="SP1" direction="horizontal" verticalAlign="middle"><Text size="small">{row.wixTxnId}</Text><IconButton onClick={() => {
                window.open(`https://manage.wix.com/dashboard/${siteId}/payments-dashboard/payment/${row.wixTxnId}`)
            }} size="tiny" skin="light" priority="primary"><ExternalLinkSmall /></IconButton></Box>)
        },
        { title: '發票編號', render: (row: { ecpayInvoiceNumber: string; }) => row.ecpayInvoiceNumber },
        { title: '發票開立日期', render: (row: { ecpayInvoiceDate: string; }) => row.ecpayInvoiceDate },
        { title: '狀態', render: (row: { status: any; }) => row.status },
        {
            title: "", render: (row: {
                ecpayInvoiceDate: string;
                ecpayInvoiceNumber: string; wixTxnId: string;
            }) => (<TableActionCell
                size="small"
                primaryAction={primaryAction(row.wixTxnId, !!row.ecpayInvoiceNumber && !!row.ecpayInvoiceDate)}
                secondaryActions={secondaryActions(row.wixTxnId)}
                numOfVisibleSecondaryActions={1}
                popoverMenuProps={{ appendTo: 'window' }}
                moreActionsTooltipText="更多動作"
            />)
        }
    ];

    return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
            <Layout>
                <Cell>
                    <Notification onClose={() => setShowSuccessBanner(false)} theme="success" show={showSuccessBanner}>
                        <Notification.TextLabel ellipsis={false}>
                            {successText}
                        </Notification.TextLabel>
                        <Notification.CloseButton />
                    </Notification>
                    <Notification onClose={() => setShowErrorBanner(false)} theme="error" show={showErrorBanner}>
                        <Notification.TextLabel ellipsis={false}>
                            {errorText}
                        </Notification.TextLabel>
                        <Notification.ActionButton onClick={() => window.Intercom('showNewMessage', `回報問題`)}>
                            聯繫支持
                        </Notification.ActionButton>
                        <Notification.CloseButton />
                    </Notification>
                </Cell>
            </Layout>
            <Page height='100vh'>
                <Page.Header
                    title="發票"
                    subtitle="開立及管理通過Wix網站交易開立的綠界電子發票"
                    actionsBar={
                        <Box direction="horizontal" gap="SP1">
                            <Cell span={6}>
                                <Button size="medium" skin='standard' onClick={() => setShownSettingsModal(true)}>
                                    設定
                                </Button>
                            </Cell>
                            <Cell span={6}>
                                <Button disabled={isUpgraded} size="medium" skin='premium' prefixIcon={<PremiumFilled />} onClick={() => { setShownUpgradeModal(true) }}>
                                    升級應用
                                </Button>
                            </Cell>
                            <Cell span={2}>
                                <Tooltip content="幫助中心">
                                    <IconButton priority="secondary">
                                        <HelpCircle onClick={() => { window.open("https://support.certifiedcode.us/zh-TW/collections/3699350-%E8%AA%8D%E8%AD%89%E4%BB%A3%E7%A2%BC-invoicing") }} />
                                    </IconButton>
                                </Tooltip>
                            </Cell>
                        </Box>}
                />
                <Page.Content>
                    {showTrialEndedSection ? <Box gap={"SP4"} direction="vertical">
                        <Cell>
                            <SectionHelper
                                actionText="升級應用"
                                appearance="premium"
                                onAction={() => setShownUpgradeModal(true)}
                                title="想繼續開立發票？"
                            >
                                此應用試用期已結束，立即升級 Premium 方案，即可開立發票。
                            </SectionHelper>
                        </Cell>
                        <Cell></Cell>
                    </Box> : <></>}
                    {transactions.length === 0 ? <Box gap={"SP4"} direction="vertical"><Cell><EmptyState
                        image={<Image width="120px" src="https://www.wix-style-react.com/storybook/EmptyState_Generic1.svg" transparent />}
                        title="建立一筆交易以繼續開立發票"
                        subtitle="要開立電子發票，網站需要有一筆已完成的交易。"
                        theme="page"
                    >
                        {/* <TextButton prefixIcon={<Add />} onClick={() => { wixNavigate(`invoices`); }}>建立新交易</TextButton> */}
                    </EmptyState></Cell><Cell></Cell></Box> : <Card stretchVertically={true} hideOverflow>
                        <TableToolbar>
                            <TableToolbar.Title>所有交易</TableToolbar.Title>
                            <TableToolbar.ItemGroup position="start">
                                <TableToolbar.Item>
                                    <Text size="small">{totalProductCount} 筆交易</Text>
                                </TableToolbar.Item>
                            </TableToolbar.ItemGroup>
                        </TableToolbar>
                        <Table
                            data={transactions}
                            columns={columns}
                            rowVerticalPadding="medium"
                            loader={
                                <Box align="center" padding="24px 0px">
                                    <Loader size="small" />
                                </Box>
                            }
                        />
                    </Card>}
                    <Modal
                        isOpen={shownSettingsModal}
                        onRequestClose={() => setShownSettingsModal(false)}
                        shouldCloseOnOverlayClick
                        screen="desktop"
                    >
                        <CustomModalLayout
                            primaryButtonText="儲存"
                            secondaryButtonText="取消"
                            onCloseButtonClick={() => setShownSettingsModal(false)}
                            secondaryButtonOnClick={() => setShownSettingsModal(false)}
                            primaryButtonOnClick={() => updateSettings()}
                            removeContentPadding
                            title="應用設定"
                            content={
                                <Page>
                                    <Page.Content>
                                        <Box marginTop={5} display="block">
                                            <Layout>
                                                <Cell span={12}>
                                                    <Card>
                                                        <Card.Header title='綠界商家帳號' subtitle='連線至綠界電子發票平台，以建立電子發票' />
                                                        <Card.Divider />
                                                        <Card.Content>
                                                            <Box direction='vertical' gap="SP2">
                                                                <FormField label="綠界商家代號" infoContent="格式為七位數數字">
                                                                    <Input clearButton value={merchantId} onChange={(e) => setMerchantId(e.target.value)} />
                                                                </FormField>
                                                                <FormField label="HashKey">
                                                                    <Input clearButton value={hashKey} onChange={(e) => setHashKey(e.target.value)} />
                                                                </FormField>
                                                                <FormField label="HashIV">
                                                                    <Input clearButton value={hashIV} onChange={(e) => setHashIV(e.target.value)} />
                                                                </FormField></Box>
                                                        </Card.Content>
                                                    </Card>
                                                </Cell>
                                                <Cell span={12}>
                                                    <Card>
                                                        <Card.Header title='自動化' subtitle='通過自動化，您可以設定電子發票的發送時間，以及發票的開立方式。（更多功能即將到來）' />
                                                        <Card.Divider />
                                                        <Card.Content>
                                                            <Box direction="vertical" />
                                                            <Box verticalAlign="middle" align="space-between" padding={'0px 0px 18px'}>
                                                                <Box direction="vertical">
                                                                    <Text weight="normal">{"自動建立發票"}</Text>
                                                                    <Text secondary size="small">
                                                                        {'當交易狀態已更新為「APPROVED（已核准）」時，自動建立發票。'}
                                                                    </Text>
                                                                </Box>
                                                                <ToggleSwitch checked={isCreateOnTransaction} onChange={(e) => setIsCreateOnTransaction(e.target.checked)} />
                                                            </Box>
                                                        </Card.Content>
                                                    </Card>
                                                </Cell>
                                            </Layout>
                                        </Box>
                                    </Page.Content>
                                </Page>
                            }
                        />
                    </Modal>
                    <Modal
                        isOpen={shownUpgradeModal}
                        onRequestClose={() => setShownUpgradeModal(false)}
                        shouldCloseOnOverlayClick
                        screen="desktop"
                    ><AnnouncementModalLayout
                        theme="premium"
                        illustration={'https://www.wix-style-react.com/storybook/generic_upgrade.svg'}
                        title="開始透過Wix開立發票"
                        primaryButtonText="開始訂閱"
                        primaryButtonOnClick={() => { getUpgradeUrl(false) }}
                        linkOnClick={() => { getUpgradeUrl(true) }}
                        linkText="或購買永久授權，讓您無需每月/年付費即可使用。"
                        onCloseButtonClick={() => { setShownUpgradeModal(false) }}
                    >
                            <Text>
                                升級「綠界科技 ECPay 電子發票」應用，以開立發票。
                            </Text>
                        </AnnouncementModalLayout>
                    </Modal>
                    <Modal
                        isOpen={shownTransactionModal}
                        shouldCloseOnOverlayClick={true}
                    >
                        <CustomModalLayout
                            onCloseButtonClick={() => { setShownTransactionModal(false) }}
                            title={"交易 " + currentTransaction}
                            subtitle="此處顯示發送及接收綠界的原始數據"
                            footnote={
                                <Text size='small'>
                                    遇到問題？我們的支持團隊在線為您提供幫助。
                                </Text>
                            }
                            content={
                                <Box direction="vertical" gap={"SP2"}>
                                    <FormField labelSize="small" label="請求">
                                        <InputArea
                                            value={currentTransaction ? convertObjToList((dashboard as any)['invoices'].find((v: { _id: string; }) => v._id === currentTransaction).ecpayRequest, (requestMap as any)) : ""}
                                            autoGrow={true}
                                            minRowsAutoGrow={1}
                                            readOnly={true}
                                        />
                                    </FormField>
                                    <FormField labelSize="small" label="回應">
                                        <InputArea
                                            value={currentTransaction ? convertObjToList((dashboard as any)['invoices'].find((v: { _id: string; }) => v._id === currentTransaction).ecpayResponse, (responseMap as any)) : ""}
                                            autoGrow={true}
                                            minRowsAutoGrow={1}
                                            readOnly={true}
                                        />
                                    </FormField>
                                    <Box></Box>
                                    <Box></Box>
                                </Box>
                            }
                        />
                    </Modal>
                    <Layout>
                        <Cell>
                            <PageSection title="為您推薦" showDivider />
                        </Cell>
                        <Cell span={6}>
                            <Card>
                                <MarketingLayout
                                    title="接受線上付款"
                                    description="透過 Flows. 整合OS，您可以使用本地支付服務商讓客戶使用台灣付款方式。"
                                    actions={<Button size="small" onClick={() => { window.open("https://www.flows.tw/payments/ecpay") }}>瞭解更多</Button>}
                                    size="tiny"
                                    badge={<Badge size="small">第三方整合</Badge>}
                                    image={
                                        <Box width="100%" align="right">
                                            <Image
                                                width="120px"
                                                src="https://www.wix-style-react.com/storybook/generic_upgrade.svg"
                                                transparent
                                            />
                                        </Box>
                                    }
                                />
                            </Card>
                        </Cell>
                        <Cell span={6}>
                            <Card>
                                <MarketingLayout
                                    title="與支持團隊聯繫"
                                    description="當您遇到問題時，我們的支持團隊會幫助您解決並提供建議。"
                                    actions={<Button size="small" onClick={() => { window.open("https://calendly.com/certifiedcode/flows-partners") }}>預約通話</Button>}
                                    size="tiny"
                                    hiddenBadge
                                    alignItems="stretch"
                                    image={
                                        <Box width="100%" align="right">
                                            <Image
                                                width="120px"
                                                src="https://www.wix-style-react.com/storybook/PromotionalBookingsUpgrade.svg"
                                                transparent
                                            />
                                        </Box>
                                    }
                                />
                            </Card>
                        </Cell>
                    </Layout>
                </Page.Content>
            </Page>
        </WixDesignSystemProvider>
    )
}

export default Home;
